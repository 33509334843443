

import React from 'react'
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Card } from '@mui/material';
import { Link } from 'react-router-dom';
import Card2 from '../layout/Card2';
import ReportVisuals from '../layout/ReportVisuals';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Dashboard = () => {
  return (
    <div style={{ background: "#e9ecef" }}>
      <Box
        className="mainLayout"
        sx={{ display: "flex", overflow: "hidden", overflowY: "auto" }}
      >
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div style={{ display: "grid", gap: "20px" }}>
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  paddingBottom: "1rem",
                }}
              >
                Reports visuals
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "49% 49%",
                  height: "400px",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "1rem",
                    borderRadius: "10px",
                  }}
                >
                  <ReportVisuals visualName="972ddb4e17334ea505dd" pageName= "ReportSectioneb8c865100f8508cc533"/>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "1rem",
                    borderRadius: "10px",
                  }}
                >
                  <ReportVisuals visualName="47eb6c0240defd498d4b" pageName= "ReportSectioneb8c865100f8508cc533"/>
                </div>
              </div>

              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  paddingBottom: "1rem",
                  paddingTop: "2rem",

                }}
              >
                Reports
              </div>

              <Card2 />
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Dashboard

