import * as React from "react";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { white } from "@mui/material/colors";
import { Divider } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

import AutoModeIcon from '@mui/icons-material/AutoMode';
import ErrorIcon from '@mui/icons-material/Error';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';
export default function CustomCards(props) {


  return (
    <Card sx={{ maxWidth: 345 }}>
     
        <div style={{display:"flex",gap:"10px",padding:"1rem",alignContent:"baseline",alignItems:"flex-start"}}>
        <div style={{paddingTop:"6px"}}>
        <Avatar sx={{ boxShadow: "0px 0px 4px rgba(0,0,0,0.8)",backgroundColor:"white" }} aria-label="recipe">
        {props.title === "Active Users" ? <GroupsIcon sx={{color:"#333"}}/> : props.title === "Sign Up" ? <AutoModeIcon sx={{color:"#333"}}/> : props.title === "Open issue" ? <ErrorIcon sx={{color:"#333"}}/> : props.title === "Feedback" ? <AssignmentIcon sx={{color:"#333"}}/> : null}
           
            
        </Avatar>
      </div>

      <div style={{display:"grid",gap:"5px"}}>
        <span style={{
            fontSize: "20px",
            color:"gray"
          }}>{props.title}</span>
        <span
          style={{
            fontWeight: "600",
            fontSize: "22px",
            color:"3d3d3d"
          }}
        >
          {props.number}
        </span>
      </div>
        </div>

      <Divider />

      <div style={{ padding: "1rem", display: "flex", gap: "10px" }}>
        {props.isDown === true && (
            <>
             <TrendingDownIcon sx={{ color: "red" }} />
              <span style={{ fontWeight: "600" }}>{props.count} {props.status} vs last month</span>
            </>
        )}

        {props.isDown === false && (
            <>
             <TrendingUpIcon sx={{ color: "green" }} />
              <span style={{ fontWeight: "600" }}>{props.count} {props.status} vs last month</span>
            </>
        )}
       
      </div>
    </Card>
  );
}
