import React, { useState,useEffect } from 'react'
import SideNavbar from '../nav/SideNavbar'
import Box from '@mui/material/Box';
import axios from 'axios';

const MainLayout = () => {


  return (
    <Box>
        <SideNavbar/>
    </Box>
  )
}

export default MainLayout
