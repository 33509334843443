import React from 'react'
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import pathImg from '../assests/img/homeimg.svg'
import AutoSlider from '../layout/AutoSlider';
import PieChartsApex from '../chats/PieChartsApex'
import VericalBarCharts from '../chats/VericalBarCharts'
import CustomCards from '../layout/CustomCards';


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Home = () => {
  return (
    <div>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden",overflowY:"auto" }}>
            <MainLayout />

            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <DrawerHeader />
                <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

                <div id="mainHome">

                  <div style={{padding:"5px",display:"grid",gap:"2rem"}}>

                        <div style={{display:'grid',gap:"1rem",gridTemplateColumns:"70% 29%"}}>
                          <div className='homePage_card'>

                                <div style={{display:"flex",justifyContent:"space-between",gap:"3rem"}}>
                                  
                                  <div className='box-flex-1'>
                                  <div className='welcome-back' style={{display:"grid"}}>
                                      <span className='welcome-back-text-1'>
                                        Welcome back 👋 
                                      </span>
                                      <span className='welcome-back-text-2'>Nabasundar Rout</span>
                                    </div>

                                    <span>
                                        If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.
                                    </span>
                                    <div className='box-cls-2'>
                                      <button className='btn_go_now'>Go Now</button>
                                    </div>
                                  </div>

                                  <div className='box-flex-2'>
                                        <img  style={{width:"100%"}} alt='imgData' src={pathImg}/>
                                  </div>
                                  
                                </div>
                            
                          </div>

                          <div style={{width:"100%",backgroundColor:"#eee", height:"300px",borderRadius:"6px"}}>
                              <AutoSlider/>
                          </div>


                        </div>

                        <div style={{display:"grid",gridTemplateColumns:"24% 24% 25% 25%",gap:"10px"}}>

                              <div><CustomCards title="Active Users" number="200" isDown={false} count ={10} status ="increase"/></div>
                              <div><CustomCards title="Sign Up" number="500"  isDown={true} count ={12} status ="decrease"/></div>
                              <div><CustomCards title="Open issue" number="700"  isDown={true} count ={15} status ="decrease"/></div>
                              <div><CustomCards title="Feedback" number="1000"  isDown={false} count ={20} status ="increase"/></div>


                        </div>

                        <div style={{display:'grid',gap:"1rem",gridTemplateColumns:"29% 70%"}}>
                            <div style={{padding:"0px",boxShadow: "0px 0px 4px rgba(0,0,0,0.2)",backgroundColor:"white"}} className='homePage_card_1'>
                                <PieChartsApex/>
                            </div>

                            <div style={{padding:"0px",boxShadow: "0px 0px 4px rgba(0,0,0,0.2)",backgroundColor:"white"}} className='homePage_card_1'>
                                <VericalBarCharts/>
                            </div>
                        </div>


                  </div>
                    

                </div>

            </Box>

        </Box>
    </div>
  )
}

export default Home
