import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import img1 from '../assests/img/power-bi-report-1.jpg'
import { Link } from 'react-router-dom';

export default function Card2() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <div style={{position:"relative"}}>
      
        <div style={{padding:"10px"}}>
           <div style={{display:"grid"}}>
           <span style={{fontWeight:"700",fontSize:"20px"}}>
             Market Share
           </span>
           <span style={{fontWeight:"normal",fontSize:"14px"}}>
             Last update: 14th Feb 2024
           </span>
           <span style={{fontWeight:"normal",fontSize:"14px"}}>
            Created By: Nabasundar
           </span>
           <span style={{display:'grid',justifyContent:"flex-start",position:"absolute",right:"10px",top:"10px"}}>
              <span style={{fontWeight:"normal",fontSize:"14px",backgroundColor:"lightblue",padding:"2px",paddingLeft:"4px",paddingRight:"4px",borderRadius:"4px"}}>PowerBi</span>
           </span>
           </div>
         
        </div>
      </div>
      <CardActions>
        <Link to={`/user/report/1`} style={{background:"#333", width:"80px",textAlign:"center",color:"white",height:"35px",display:"grid",alignItems:"center",borderRadius:"6px"}}>
          View
        </Link>
      </CardActions>
    </Card>
  );
}