import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/auth/Login';
import Powerbi from './components/pages/Powerbi';
import Dashboard from './components/pages/Dashboard';

const App = () => {
  // localStorage.setItem("Auth",true)
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/user/report/1" element={<Powerbi />} />
          <Route path="/user/report" element={<Dashboard />} />
          {/* <Route path="/user/invoice" element={<Invoice />} />
          <Route path="/user/management" element={<UserManagement />} />
          <Route path="/user/fileManager" element={<FileManager />} />
          <Route path="/user/kanban" element={<Kanban />} />
          <Route path="/user/directory" element={<DataDirectory />} />
          <Route path="/user/chats" element={<TeamsChats />} />
          <Route path="/user/report/1" element={<Powerbi />} />
          <Route path="/user/report" element={<Dashboard />} /> */}
          {/* <Route path="/donor" element={<DonorList />} />
          <Route path="/electoral" element={<ElecList />} /> */}

        </Routes>
      </Router>
    </div>
  );
};

export default App;
