import React, { useEffect, useState } from 'react'
import { PowerBIEmbed } from "powerbi-client-react";
import { models,powerbi } from "powerbi-client";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const ReportVisuals = (props) => {

    const [data, setPbireports] = useState("")

    const baseUrl = "https://wabi-us-east-a-primary-redirect.analysis.windows.net/playground/report/45ce895e-0693-46c7-b984-c849948414e1/GenerateToken";

    useEffect(() => {
      const fetchSearchByname = async () => {
        await fetch(`${baseUrl}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                setPbireports(json);
              //  console.log(isLoading);
              });
            }
          })
          .catch((err) => {
            setPbireports(null);
            console.log("iserror");
          });
      };
      fetchSearchByname();
    }, [baseUrl]);

    //  var reportPages = await window.report.getPages(); 
    //     let firstPage = reportPages[0];
    //     let visuals = await firstPage.getVisuals();
    //     console.log(visuals);
    //     console.log(firstPage);
 
  return (
             
    <div style={{height:"400px"}}>
       <PowerBIEmbed
            embedConfig={{
              type: "visual", // Supported types: report, dashboard, tile, visual, and qna.
              id: data.id,
              embedUrl: data.embedUrl,
              accessToken: data.token,
              tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
              pageName: `${props.pageName}`,
              visualName: `${props.visualName}`,
              permissions: models.Permissions.All,
              viewMode: models.ViewMode.View,


              settings: {
                navContentPaneEnabled: false,
                filterPaneEnabled: false,
                persistentFiltersEnabled: true,
                bars: {
                  actionBar: {
                    visible: true,
                  },
                  statusBar: {
                    visible: false,
                  },
                },
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
               
                [
                  "loaded",
                  async function () {
                    console.log("Report loaded");
                    
                
                   
                  },
                ],
                
                [
                  "dataSelected",
                  function (event) {
                    console.log("Data selected event triggered");
                    console.log(event.detail);

                      var data = event.detail;
                      var table = data.visual.table;
                      var columns = data.visual.columns;
          
                      console.log('Data Selected - Table: ' + table);
                      console.log('Data Selected - Columns: ' + columns);
                  }
              ]
                
                ,
                [
                  "rendered",
                  async function () {
                    console.log("Report rendered");
                    
                    
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={"report-style-class"}
          />
    </div>

  )
}

export default ReportVisuals


