import React, { useEffect, useState } from 'react'
import { PowerBIEmbed } from "powerbi-client-react";
import { models,powerbi } from "powerbi-client";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Powerbi = () => {

    const [data, setPbireports] = useState("")

    const baseUrl = "https://wabi-us-east-a-primary-redirect.analysis.windows.net/playground/report/45ce895e-0693-46c7-b984-c849948414e1/GenerateToken";

    useEffect(() => {
      const fetchSearchByname = async () => {
        await fetch(`${baseUrl}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                setPbireports(json);
              //  console.log(isLoading);
              });
            }
          })
          .catch((err) => {
            setPbireports(null);
            console.log("iserror");
          });
      };
      fetchSearchByname();
    }, [baseUrl]);

    // console.log(Pbireports.token)
    // console.log(Pbireports.embedUrl)
    // console.log(Pbireports)

 
 //https://playground.powerbi.com/en-us/explore-features

//  const data = {
//     "token": "H4sIAAAAAAAEACWUN67jVgAA7_LbZ4A5GdiCYhCTmKM6Uo9JzDkYvrvX2H6qAWb--bHTqx1S-PP3zzYVtjvxuvJlk6ZFGjhQi4_c66temSyT5TJ0EhSPAEus2-4bmX95EkZlG2qe6IOi2W-js-EhVw0Oet591MM6ayjfTJptHq1C9M_dNIh7uFKnqJFY_WyIny4fP5FIA7NFJpzkm4mNOG9KObBBII-eWKLXtkmpWNCVTOvicMp816M88sT7cZZu-PSCOkmJZqwaBa28Vmrbj-xeReOF-KfKsY99oTWwzLG_ZJevS5r3OrcaRnQGxNIXBoANJMk7k-l3DOU88VnYD2NhwiEtmKzXAlwLK-vN-PBmuZoatTRvt3vs97wwrFBIScm0uAY8y8JrnhEx3fWLLjiAR-iMWzXC5kCS2ziso-sC_mzTzNzWY9lGVQKf7k2FNhUqSTzf1e3X_SzA-IuIbxTt6cdom1oevTumtHbkyFa8L-VYAM3JdmQUnjSEXIE-iyVrM75708aju0OOrymD124bF0NsK3Y20u2kfDhU4xe2j_QxiM7olPcGNZ3kVY81i7hJW2_KFfjbeDp2fCSlVtnSPZQr82YQ78sR-og8uGU5UnI_jkRcMQlJ4bzrKzmcokVNtyQNpyCnky-zmFFF4pe1w0akxGCxPUc33g07nAFHNhc2sD63ogdL0dVyZOx9fp0FSwsn8TtS3y3nXTrhTlj4UNLXuLfADR0uJAYL-_C43NnMOMAWTCeiPOw1wM1nWaMLBativzYttzSMuvHSUPeHyS36qfa0mdvSjOshB75h7HsurqfS9QFsUzXBUku9AjjPaL4RWh0cV6L0BLrOz1WJmwGG8uA6Tl_gf_36-etHmK9xHfT8-p2JNSHwtJ4TzpZpUIiB_wDYUFUZDnvFvQVkE5sx-qaVr85ypUYaBAWkWOPS9lsQs1fd3gpJYu-549-z0E2Zb1SVbOSFJInWSyjt1DPHmahx5HvoudKIhRc3yl27PVUQyZAL8EqveedUbZjf748NBMW25gI9tJ6crpTF4Ef0t63DECG43025j75C8ZGMIqwQUBhpQfPTJ6JCcL1I8u63EGzxGz4ZnEIF9ok2Loyj9X7uubJb0-hCZfpavbb6tkgv_Fu5gGbgZdAVKIO8zehSQUFuETYfY2c-o-8uHJx8W1xG85D2bhJRq9xiMFUSU8HBwx1ERpfVk-rjuAjGPlGCV4xJ-9f5o_kaq3xWw_9npNB55_h1VafwYF_PUtA14w_l1WWfrtuc_8b08OWGAXYYBid4qhrngco4YUkNSAXR2oZkWSeMyUVYMZ0HibdlhBN3u7dbw42VYCXQsj_gFRlFMsnVKTGKbezrMEUvL6Z9nSQlO0PqjwBVqiNemiMORyMQI8SMPHySjAANUKoxGd-6kq2RTBKWPxjMPfo1LwYtvytkvzOpSWgnE9J7ZNRobQFmxh9IjKyrRCakw1ouJjmpEtLV_HoHMX4ufpUVr2qdTcwdMBNRc1Z5IHvtBXPb2uaie-UVijMvcrvDwhSzon2fP92tOzzPRPIHfJdEj0nsXttyeADnuSyMFVGjNGnM4UjO1qE7hlAiyKqEFSuV2uVmYF_pvnbxUZKNe_zW_O9_KURYhRoGAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNzEwMzk5NzQ2LCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=",
//     "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=45ce895e-0693-46c7-b984-c849948414e1&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d",
//     "expiration": "2024-03-14T07:02:26.9609552Z",
//     "id": "45ce895e-0693-46c7-b984-c849948414e1"
// }
 
  return (
    <div style={{background:"#e9ecef",height:"100%"}}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden",overflowY:"auto" }}>
            <MainLayout />

            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <DrawerHeader />
                <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

                <div id="mainHome">
             
                <div style={{height:"100%"}}>
       <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, and qna.
              id: data.id,
              embedUrl: data.embedUrl,
              accessToken: data.token,
              //pageName: 'ReportSectione5bc25aa0357c917eaaa',
              tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
              permissions: models.Permissions.All,
              viewMode: models.ViewMode.View,

              settings: {
                navContentPaneEnabled: false,
                filterPaneEnabled: false,
                persistentFiltersEnabled: true,
                bars: {
                  actionBar: {
                    visible: true,
                  },
                  statusBar: {
                    visible: false,
                  },
                },
                panes: {
                  filters: {
                    expanded: false,
                    visible: true,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
               
                [
                  "loaded",
                  async function () {
                    console.log("Report loaded");
                
                    const pages = await window.report;
   
    console.log(pages);
                  },
                ],
                
                [
                  "dataSelected",
                  function (event) {
                    console.log("Data selected event triggered");
                    console.log(event.detail);

                      var data = event.detail;
                      var table = data.visual.table;
                      var columns = data.visual.columns;
          
                      console.log('Data Selected - Table: ' + table);
                      console.log('Data Selected - Columns: ' + columns);
                  }
              ]
                
                ,
                [
                  "rendered",
                  async function () {
                    console.log("Report rendered");
                    
                    
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={"report-style-class"}
          />
    </div>

                </div>

            </Box>

        </Box>
    </div>
  )
}

export default Powerbi


